/*noinspection CssInvalidAtRule*/
@import-normalize; /* bring in normalize.css styles */

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
