.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonRow button {
  margin-right: 8px;
}

.party {
  text-align: center;
  font-size: 64px;
}

.party span[role="img"] {
  display: inline-block;
}
