.table {
  display: grid;
  grid-template-columns: 1fr 32px 144px 32px 546px 32px 144px 32px 1fr;
  grid-template-rows: auto 32px 144px 32px 258px 32px 144px 32px auto;
  grid-template-areas:
    ". . i i i i i . ."
    ". . . . t . . . ."
    ". . L . T . R . ."
    ". . L . . . R . ."
    ". l L . C . R r ."
    ". . L . . . R . ."
    ". . L . B . R . ."
    ". . . . b . . . ."
    ". . u u u u u . .";
}

.tutorial-board {
  grid-row: 1 / span 9;
  grid-column: 2 / span 7;
}

.tutorial-hand_and_discards {
  grid-row: 4 / span 4;
  grid-column: 4 / span 3;
}

.tutorial-play_area {
  grid-row: 3 / span 5;
  grid-column: 3 / span 5;
}

.info {
  grid-area: i;
  display: flex;
}

.centre {
  grid-area: C;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
  padding: 16px;
  border-radius: 16px;
}

.underneath {
  grid-area: u;
}

.controls button {
  margin-right: 8px;
}

.infobox {
  padding: 4px 8px;
  line-height: 1.15;
  border-radius: 1px;
  border: 2px solid #4a4a4a;
  color: #4a4a4a;
  margin-right: 8px;
  display: inline-block;
}

.top {
  grid-area: T;
}

.bottom {
  grid-area: B;
}

.right {
  grid-area: R;
  display: flex;
  flex-direction: column-reverse;
}

.hand-right {
  display: flex;
  margin: 32px 0;
}

.left {
  grid-area: L;
}

.hand-left {
  display: flex;
  margin: 32px 0;
}

.label-bottom {
  grid-area: b;
  text-align: center;
  line-height: 32px;
}

.label-right {
  grid-area: r;
  text-align: center;
  line-height: 32px;
  writing-mode: vertical-lr;
}

.label-top {
  grid-area: t;
  text-align: center;
  line-height: 32px;
}

.label-left {
  grid-area: l;
  text-align: center;
  line-height: 32px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.messages {
  margin-top: 4px;
}

.tile {
  background-size: contain;
  width: 39px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url("tiles/back.svg");
}

.message {
  display: flex;
  align-items: center;
}

.tile.inline {
  height: 32px;
  width: 26px;
  display: inline-block;
}

.left .tile,
.right .tile {
  height: 39px;
  width: 48px;
  background-image: url("tiles/backh.svg");
}

.selecting .tile {
  opacity: 0.5;
}

.selectable .tile {
  cursor: pointer;
}

.selecting .tile.selected {
  opacity: 1;
}

.tile.draggable {
  cursor: move;
}

.tile.dragging {
  opacity: 0;
}

.rack {
  display: flex;
}

.top .rack,
.bottom .rack {
  height: 48px;
}

.left .rack,
.right .rack {
  width: 48px;
}

.top .rack {
  flex-direction: row-reverse;
}

.left .rack {
  flex-direction: column;
}

.right .rack {
  flex-direction: column-reverse;
}
